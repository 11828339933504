import React, {Component} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import {Loader, Icon} from 'semantic-ui-react';
import {Analytics, BrowserWarningMessage, intlShape} from '@ecosio/components';
import {NewHeader, NewSidebar} from '@ecosio/customer-layout';
import {switchModule, MODULE_TYPES, getApplicationUrls} from '@ecosio/auth';
import {localesMap as fallbackLocales, switchLocale} from '@ecosio/locales';
import {FormattedMessage, injectIntl} from 'react-intl';
import axios from 'axios';
import configShape from '../../shapes/configShape';
import {scenarioShape} from '../../shapes/scenarios';
import {fetchScenarios} from '../../reducers/scenariosList';
import {DynamicMessage} from '../../helpers/translate';
import {getEnvelopesDefaultSort} from '../Envelopes';
import {BASE_URL} from '../../constants';
import NewsAndFeedbackComponent from '../NewsAndFeedback';
import {getScenarioCategoriesSideBarItems} from './layoutSideBarUtils';

const mapStateToProps = ({locales, config, scenariosList}) => ({
  scenarios: scenariosList.data,
  locale: locales.locale,
  config,
});

const mapDispatchToProps = (dispatch) => ({
  fetchScenarios: () => dispatch(fetchScenarios()),
  switchModule: (module) => dispatch(switchModule(module)),
});

export const getScenarioName = (scenario) => {
  if (
    !scenario ||
    !scenario.nameTranslationKey ||
    typeof scenario.nameTranslationKey !== 'string'
  ) {
    console.error('Unable to retrieve scenario name from scenario');
    return 'GENERAL_DOCUMENTS';
  }

  return scenario.nameTranslationKey;
};

export const getScenarioDescription = (scenario) => {
  if (
    !scenario ||
    !scenario.descriptionTranslationKey ||
    typeof scenario.descriptionTranslationKey !== 'string'
  ) {
    console.error('Unable to retrieve scenario description from scenario');
    return null;
  }

  return scenario.descriptionTranslationKey;
};

const getLocaleLinks = (config, locale) => {
  try {
    const externalLinks = config.userConfig.externalLinks;

    let localeLinks = externalLinks[locale];
    if (!localeLinks) {
      localeLinks = externalLinks['en'];
    }

    return localeLinks || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

class LayoutComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newsAndFeedbackOpened: false,
    };
  }

  componentDidMount() {
    this.props.switchModule(MODULE_TYPES.WEB_EDI);

    const {config} = this.props;
    // The webEdi is only available to using-companies
    if (get(config, 'userConfig.offeringCompany') === true) {
      const reasonSuffix = '?reason=moduleNotAvailable';
      const authServer = config.authServer;
      const redirectTo = `${authServer}${reasonSuffix}`;
      window.location.assign(redirectTo);
      return;
    }

    this.props.fetchScenarios();
  }

  switchLocale = (e, {value}) => {
    switchLocale(value, this.props.config.authServer);
  };

  switchEdiPermission = (ediPermissionUuid) => {
    axios
      .put(`/api/webediUser/setEdiPermission`, null, {
        params: {
          ediPermissionUuid: ediPermissionUuid,
        },
      })
      .then((data) => {
        // If backend sent URL for redirection -> apply that URL
        if (data.data?.redirectToAccounts === true) {
          const urls = getApplicationUrls(window.location.href);
          if (urls?.FE_CONFIG_OAUTH_SERVER?.length > 0) {
            window.location.replace(urls.FE_CONFIG_OAUTH_SERVER);
          } else {
            console.warn(
              '[WARN] Unable to determine accounts app url, redirecting to webedi base'
            );
            window.location.replace(BASE_URL);
          }
        } else {
          // Otherwise redirect to BASE app url
          window.location.replace(BASE_URL);
        }
      })

      .catch((e) => console.error('Switching ediPermission failed', e));
  };

  openNewsAndFeedbackView = () => {
    this.setState({newsAndFeedbackOpened: true});
  };

  newsAndFeedbackViewClosedCallback = () => {
    this.setState({newsAndFeedbackOpened: false});
  };
  render() {
    // We want to block the whole page rendering until we have the scenario
    // list. We can't really do anything without it.
    // TODO: better checks
    if (!this.props.scenarios.length) {
      return <Loader active />;
    }

    const {locale, config} = this.props;
    const userID = config?.userConfig?.email;

    const localeLinks = getLocaleLinks(config, locale);

    let sideBarItems = [];
    const scenarioCategoriesSideBarItems = getScenarioCategoriesSideBarItems(
      config?.userConfig,
      this.props.scenarios
    );

    sideBarItems = sideBarItems.concat(
      scenarioCategoriesSideBarItems.map((category, index) => {
        const categoryScenarios = category?.scenarios || [];
        const defaultSortEnvelopes = categoryScenarios[0]?.defaultSortEnvelopes;

        const envelopesSort = getEnvelopesDefaultSort(
          defaultSortEnvelopes,
          categoryScenarios[0].uuid,
          userID
        );
        let link = `/scenario/${categoryScenarios[0].uuid}`;

        if (envelopesSort) {
          link = `/scenario/${categoryScenarios[0].uuid}${envelopesSort}`;
        }

        return {
          idx: sideBarItems.length + index,
          translation: (
            <FormattedMessage id={category?.categoryNameTranslationKey} />
          ),
          path: link,
          link: (
            <NavLink
              isActive={(_, {pathname}) =>
                category.scenarios.find((sc) =>
                  pathname.includes(`/scenario/${sc.uuid}`)
                )
              }
              data-spec={`sidebar-scenario-${index}`}
              to={link}>
              <div className="icon">
                <Icon name={category.categoryIcon} />
              </div>
              <FormattedMessage id={category.categoryNameTranslationKey} />
            </NavLink>
          ),
          childNavs: category.scenarios.map((scenario, index) => {
            const defaultSortEnvelopes = scenario?.defaultSortEnvelopes;

            const envelopesSort = getEnvelopesDefaultSort(
              defaultSortEnvelopes,
              scenario.uuid,
              userID
            );

            let link = `/scenario/${scenario.uuid}`;

            if (envelopesSort) {
              link = `/scenario/${scenario.uuid}${envelopesSort}`;
            }

            return {
              key: index,
              translation: <FormattedMessage id={getScenarioName(scenario)} />,
              path: link,
              link: (
                <NavLink
                  isActive={(_, {pathname}) =>
                    pathname.includes(`/scenario/${scenario.uuid}`)
                  }
                  data-spec={`sidebar-scenario-${index}`}
                  to={link}>
                  <div className="icon">
                    <Icon name={scenario.webEdiSideBarIcon} />
                  </div>
                  <FormattedMessage id={getScenarioName(scenario)} />
                </NavLink>
              ),
            };
          }),
        };
      })
    );

    sideBarItems = sideBarItems.concat(
      this.props.scenarios
        .filter(
          (scenario) =>
            !scenarioCategoriesSideBarItems.find((category) =>
              category.scenarios.find((sc) => sc.uuid === scenario.uuid)
            )
        )
        .map((scenario, idx) => {
          const defaultSortEnvelopes = scenario?.defaultSortEnvelopes;

          const envelopesSort = getEnvelopesDefaultSort(
            defaultSortEnvelopes,
            scenario.uuid,
            userID
          );

          let link = `/scenario/${scenario.uuid}`;

          if (envelopesSort) {
            link = `/scenario/${scenario.uuid}${envelopesSort}`;
          }

          return {
            idx: idx,
            translation: <FormattedMessage id={getScenarioName(scenario)} />,
            path: link,
            link: (
              <NavLink
                isActive={(_, {pathname}) =>
                  pathname.includes(`/scenario/${scenario.uuid}`)
                }
                data-spec={`sidebar-scenario-${idx}`}
                to={link}>
                <div className="icon">
                  <Icon name={scenario.webEdiSideBarIcon} />
                </div>
                <FormattedMessage id={getScenarioName(scenario)} />
              </NavLink>
            ),
            description: (
              <FormattedMessage id={getScenarioDescription(scenario)} />
            ),
          };
        })
    );

    sideBarItems = sideBarItems.concat(
      localeLinks.map((localeLink, idx) => ({
        idx: idx,
        translation: <DynamicMessage id={localeLink.translationKey} />,
        path: localeLink.url,
        link: (
          <React.Fragment>
            <a
              data-spec={`sidebar-localeLink-${idx}`}
              href={localeLink.url}
              target="_blank"
              rel="noopener noreferrer">
              <div className="icon">
                <Icon name={localeLink.linkIcon} />
              </div>
              <DynamicMessage id={localeLink.translationKey} />
            </a>
          </React.Fragment>
        ),
      }))
    );

    const localesMap = config.userConfig?.supportedLocales || fallbackLocales;

    return (
      <div id="webedi-layout" data-spec="layout">
        <NewsAndFeedbackComponent
          newsFeedbackConfiguration={
            config.userConfig?.newsFeedbackConfiguration
          }
          renderView={this.state.newsAndFeedbackOpened}
          intl={this.props.intl}
          closeViewCallbackFn={this.newsAndFeedbackViewClosedCallback}
        />
        <Analytics />
        <BrowserWarningMessage static />
        <NewHeader
          baseUrl={this.props.config.authServer}
          userConfig={this.props.config.userConfig}
          locale={this.props.locale}
          onSwitchLocale={this.switchLocale}
          selectedModule={this.props.config.selectedModule}
          localesMap={localesMap}
          isAccounts={false}
          authServer={this.props.config.authServer}
          onSwitchEdiPermission={this.switchEdiPermission}
        />

        <NewSidebar
          authorities={this.props.config.userConfig?.authorities}
          accountsBaseUrl={this.props.config.authServer}
          isAccounts={false}
          items={sideBarItems}
          userConfig={this.props.config.userConfig}
          openFeatureBaseFn={this.openNewsAndFeedbackView}>
          {this.props.children}
        </NewSidebar>
      </div>
    );
  }
}

LayoutComponent.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.any,
  config: configShape,
  fetchScenarios: PropTypes.func,
  scenarios: PropTypes.arrayOf(scenarioShape),
  switchModule: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutComponent))
);
