import React, {Suspense, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route, withRouter} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';
import {useConfig} from '@ecosio/auth';
import {connect, useSelector} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {
  isInternetExplorerEOL,
  BrowserWarningMessage,
  ToastContainer,
} from '@ecosio/components';
import {useIntl} from 'react-intl';
import axios from 'axios';
import Layout from './components/Layout/index';
import {getEnvelopesDefaultSort} from './components/Envelopes';
import GracefulConfigurationError from './components/Error/GracefulConfigurationError';
import WebEdiSettingsPage from './components/Settings/WebEdiSettingsPage';
import DownloadProgressIndicator from './helpers/components/DownloadProgressIndicator';
// import AppLoader from './components/AppLoader';
import TermsOfServiceNotAcceptedBanner from './helpers/components/TermsOfServiceNotAcceptedBanner';
import AsyncPage from './AsyncPage';

export const allowedPaths = [];

const LoadingPage = ({scenariosLoaded, data, userID}) => {
  const defaultSortEnvelopes = data[0]?.defaultSortEnvelopes;

  const envelopesSort = getEnvelopesDefaultSort(
    defaultSortEnvelopes,
    data[0].uuid,
    userID
  );
  if (scenariosLoaded && data.length) {
    let link = `/scenario/${data[0].uuid}`;
    if (envelopesSort) {
      link = `/scenario/${data[0].uuid}${envelopesSort}`;
    }

    return <Redirect to={link} />;
  }
  return <Loader active />;
};

LoadingPage.propTypes = {
  scenariosLoaded: PropTypes.bool,
  data: PropTypes.array,
  userID: PropTypes.string.isRequired,
};
const mapStateToProps = ({scenariosList, config}) => ({
  ...scenariosList,
  userID: config?.userConfig?.email,
});
const RedirectLoadingPage = connect(mapStateToProps)(LoadingPage);

const enhancedStateToProps = ({scenariosList, config}) => ({
  scenariosList,
  userID: config?.userConfig?.email,
  userData: config?.userConfig,
});

/**
 * Injects the scenarios array into the routed component
 * and creates an inner error boundary which lets the user
 * still navigate and/or logout when something crashes.
 */
class ErrorBoundaryAndScenarioRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // https://reactjs.org/docs/react-component.html#componentdidupdate
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({error: null});
    }
  }

  componentDidCatch(error) {
    this.setState({error});
  }

  render() {
    if (this.state.error) {
      return <GracefulConfigurationError error={this.state.error} />;
    }

    const {componentName, scenariosList, userID, userData, ...rest} =
      this.props;
    return (
      <Route
        {...rest}
        render={(props) => (
          <AsyncPage
            componentName={componentName}
            scenarios={scenariosList.data}
            userID={userID}
            userData={userData}
            ediPermissions={scenariosList.ediPermissions}
            {...props}
          />
        )}
      />
    );
  }
}

ErrorBoundaryAndScenarioRoute.propTypes = {
  componentName: PropTypes.string.isRequired,
  scenariosList: PropTypes.object,
  location: PropTypes.object,
  userID: PropTypes.string.isRequired,
  userData: PropTypes.object,
};

const ScenarioRoute = withRouter(
  connect(enhancedStateToProps)(ErrorBoundaryAndScenarioRoute)
);

export const Routes = {
  Scenario: '/scenario/:scenarioUuid',
  DailyDocuments: '/scenario/:scenarioUuid/dailyDocuments',
  MonthlyReport: '/scenario/:scenarioUuid/monthlyReport',
  FileUpload: '/scenario/:scenarioUuid/fileUpload',
  Dashboard: '/scenario/:scenarioUuid/dashboard',
  Envelope: '/scenario/:scenarioUuid/envelope/:envelopeUuid',
  DocumentView:
    '/scenario/:scenarioUuid/envelope/:envelopeUuid/doc/:documentUuid',
  DocumentCreate:
    '/scenario/:scenarioUuid/envelope/:envelopeUuid/doc/:documentUuid/create/:documentType',
  Settings: '/settings',
  ConfigError: '/config-error',
};

const RoutesGuardWrapper = ({children}) => {
  const config = useSelector((state) => state.config);
  const mfaEnforcedOnUser = config?.userConfig?.mfaEnforcedOnUser;
  const accountsPath = config?.authServer;
  const intl = useIntl();

  useEffect(() => {
    if (mfaEnforcedOnUser) {
      window.location.href = `${accountsPath}/settings`;
    }
  }, [accountsPath, intl, mfaEnforcedOnUser]);

  return <>{children}</>;
};

RoutesGuardWrapper.propTypes = {children: PropTypes.node};

const parseModules = (rawString) => {
  try {
    if (Array.isArray(rawString)) {
      return rawString;
    }

    if (typeof rawString === 'undefined') {
      return [];
    }

    return JSON.parse(rawString);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadCustomConfig = async () => {
  const userConfig = await axios
    .get('/api/client-config')
    .then((result) => result.data);

  let enabledModules = '[]';
  try {
    // TODO this should at some point be moved to the customer-layout
    // and it should load this asynchronously by itself, no need to block
    // application loading here. But for now it's better than before
    const enabledModulesResult = await axios.get(
      '/api/client-config/enabled-modules'
    );
    enabledModules = enabledModulesResult?.data;
  } catch (error) {
    console.error(error);
  }

  const modules = parseModules(enabledModules);
  return Object.assign(userConfig, {modules});
};

/**
 * *************** IMPORTANT ***********
 * When you change/remove/add routes here, you need to fix the nginx.conf
 * file for this app in the backend/src/main/resources folder.
 *
 */
const AppRouter = ({history}) => {
  const {loading} = useConfig({allowedPaths, loadCustomConfig});

  if (loading) {
    // too much - ML will come up with a sketch
    //return <AppLoader />;
    return <Loader active />;
  }

  return (
    <ConnectedRouter history={history}>
      <RoutesGuardWrapper>
        <Suspense fallback={<Loader active />}>
          <Layout>
            <TermsOfServiceNotAcceptedBanner />
            <ToastContainer
              position="top-center"
              onlyShowLast
              dataSpec="toast"
            />
            <DownloadProgressIndicator />

            {isInternetExplorerEOL() ? <BrowserWarningMessage static /> : null}

            <Route exact path="/" component={RedirectLoadingPage} />
            <ScenarioRoute
              exact
              path={Routes.Scenario}
              componentName="ScenariosComponent"
            />

            <ScenarioRoute
              exact
              path={Routes.DailyDocuments}
              componentName="DailyDocumentPage"
            />

            <ScenarioRoute
              exact
              path={Routes.MonthlyReport}
              componentName="MonthlyReportsPage"
            />
            <ScenarioRoute
              exact
              path={Routes.FileUpload}
              componentName="FileUploadScenarioPage"
            />

            <ScenarioRoute
              exact
              path={Routes.Dashboard}
              componentName="DashboardScenarioPage"
            />

            <ScenarioRoute
              exact
              path={Routes.Envelope}
              componentName="EnvelopePage"
            />

            <ScenarioRoute
              exact
              path={Routes.DocumentView}
              componentName="ViewDocumentSwitchComponent"
            />

            <ScenarioRoute
              exact
              path={Routes.DocumentCreate}
              componentName="CreateDocumentSwitchComponent"
            />
            <Route
              exact
              path={Routes.Settings}
              component={WebEdiSettingsPage}
            />
            <Route
              exact
              path={Routes.ConfigError}
              component={GracefulConfigurationError}
            />
          </Layout>
        </Suspense>
      </RoutesGuardWrapper>
    </ConnectedRouter>
  );
};

AppRouter.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AppRouter;
