import React from 'react';
import {Form, Grid, Header} from 'semantic-ui-react';
import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import {CollapseBox, toast} from '@ecosio/components';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {Input, Form as FinalForm} from '@ecosio/pathform';
import {
  DRAFTS_DELAY_ENABLED,
  DRAFTS_DELAY_TIME,
  PACKAGING_NUMBER_INPUT_SSCC,
  SETTING_TYPE_GS1,
  SETTING_TYPE_SSCC,
  SSCC_PACKAGE_NUMBER_MAX_LENGTH,
} from '../../constants';
import {loadTheme} from '../../helpers/utils';
import {ECOSIO_THEME} from '../../helpers/constants';
import {shouldRenderDrafts} from '../DocumentDrafts/draftUtils';
import {SettingsFormWrapper} from './ReusableComponents/SettingsStyles';
import {FormFieldsWrapper} from './ReusableComponents/FormFieldsWrapper';
import SubmitButton from './ReusableComponents/SubmitButton';
import webEdiSettingsFormDecorator from './WebEdiSettingsFormDecorator';
import WebediSettingsDraftAndDelayFields from './WebediSettingsDraftAndDelayFields';

const required = (value) => (value ? undefined : 'GENERAL_REQUIRED_FIELD');
const gs1Length = (value) =>
  value
    ? value.length >= 7 && value.length <= 13
      ? undefined
      : 'GS1_LENGTH_ISSUE'
    : undefined;
const ssccLength = (field, values) => {
  if (
    values[SETTING_TYPE_SSCC] &&
    values[SETTING_TYPE_GS1] &&
    values[SETTING_TYPE_SSCC].length + values[SETTING_TYPE_GS1].length >
      SSCC_PACKAGE_NUMBER_MAX_LENGTH
  ) {
    return 'SSCC_GS1_LENGTH_ERROR';
  }
  return undefined;
};

const mustBeNumber = (value) =>
  value && value.length > 0 && isNaN(value) ? 'FIELD_NOT_NUMBER' : undefined;
const mergeValidators =
  (...validators) =>
  (field, values) =>
    validators.reduce(
      (error, validator) => error || validator(field, values),
      undefined
    );

export const convertCompanySettingsToJSONMap = (array) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item.type]: item.value,
    };
  }, initialValue);
};

const mapStateToProps = ({scenariosList, config}) => {
  return {
    scenarios: scenariosList.data,
    userConfig: config?.userConfig,
  };
};

class WebEdiSettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companySettings: {},
      initialCompanySettings: {},
      settingsConfiguration: [],
    };
  }

  componentDidMount() {
    this.fetchCompanySettingsConfiguration();
    this.fetchCompanyWebEdiSettings();
  }

  onSubmit = async (values) => {
    const {initialCompanySettings} = this.state;
    const {userConfig, intl} = this.props;
    const ssccCounterValue = values[SETTING_TYPE_SSCC]
      ? values[SETTING_TYPE_SSCC]
      : 0;

    const renderDraftFields = shouldRenderDrafts(userConfig);

    const draftSubmitData = renderDraftFields
      ? [
          {
            type: DRAFTS_DELAY_ENABLED,
            value: values[DRAFTS_DELAY_ENABLED],
            updated:
              values[DRAFTS_DELAY_ENABLED] !==
              initialCompanySettings[DRAFTS_DELAY_ENABLED],
          },
          {
            type: DRAFTS_DELAY_TIME,
            value: values[DRAFTS_DELAY_TIME],
            updated:
              values[DRAFTS_DELAY_TIME] !==
              initialCompanySettings[DRAFTS_DELAY_TIME],
          },
        ]
      : [];

    const requestData = [
      {
        type: SETTING_TYPE_GS1,
        value: values[SETTING_TYPE_GS1],
        updated:
          values[SETTING_TYPE_GS1] !== initialCompanySettings[SETTING_TYPE_GS1],
      },
      {
        type: SETTING_TYPE_SSCC,
        value: ssccCounterValue,
        updated: ssccCounterValue !== initialCompanySettings[SETTING_TYPE_SSCC],
      },
      ...draftSubmitData,
    ];
    return await this.submitCompanyWebEdiSettings(requestData)
      .then((res) => {
        this.setState({
          initialCompanySettings: Object.assign({}, values),
        });

        this.handleResult(res, intl);
      })
      .catch((e) => {
        this.setState({
          companySettings: Object.assign({}, initialCompanySettings),
        });
        this.handleNetworkError(e, intl);
      });
  };

  handleResult = (res, intl) => {
    if (res.status === 200) {
      toast({
        title: intl.formatMessage({id: 'WEBEDI_SETTINGS_UPDATE_SUCCESS_TITLE'}),
        description: intl.formatMessage({
          id: 'WEBEDI_SETTINGS_UPDATE_SUCCESS_DESCRIPTION',
        }),
        type: 'success',
        time: 4000,
      });
    }
  };

  handleNetworkError = (error, intl) => {
    if (error.response.status === 409) {
      toast({
        title: intl.formatMessage({
          id: 'WEBEDI_SETTINGS_CONCURRENT_UPDATE_TITLE',
        }),
        description: intl.formatMessage({
          id: 'WEBEDI_SETTINGS_CONCURRENT_UPDATE_DESCRIPTION',
        }),
        type: 'warning',
        time: 100 * 1000,
      });
    } else {
      console.error(error);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: error.message,
        type: 'error',
        time: 100 * 1000,
      });
    }
  };

  async fetchCompanyWebEdiSettings() {
    try {
      const response = await axios.get(`/api/settings/fetch`);

      const companySettings = convertCompanySettingsToJSONMap(
        response?.data.settings
      );

      this.setState({
        companySettings: companySettings,
        initialCompanySettings: Object.assign({}, companySettings),
      });
    } catch (error) {
      console.error(error);
    }
  }

  fetchCompanySettingsConfiguration() {
    const configs = [];
    // check if there is a CPS config with SSCC generate package number input
    this.props.scenarios.forEach((scenario) => {
      if (scenario.outboundWebDocTypes?.length > 0) {
        scenario.outboundWebDocTypes.forEach((outbound) => {
          if (outbound.cpsConfigs?.length > 0) {
            outbound.cpsConfigs.forEach((cps) => {
              if (cps.packagingNumberInput === PACKAGING_NUMBER_INPUT_SSCC) {
                configs.push(SETTING_TYPE_GS1);
              }
            });
          }
        });
      }
    });

    this.setState({
      settingsConfiguration: configs,
    });
  }

  async submitCompanyWebEdiSettings(data) {
    return axios.post(`/api/settings/update`, data);
  }

  render() {
    const {intl, userConfig} = this.props;
    const {companySettings, settingsConfiguration} = this.state;

    const formFieldsConfig =
      userConfig?.webEdiSettingsConfiguration?.formFields || {};

    const formValues = {...companySettings};
    const formEnabled = userConfig?.authorities?.includes('COMPANY_USER_ADD');

    const renderDraftFields = shouldRenderDrafts(userConfig);

    const currentTheme = loadTheme(userConfig?.theme || ECOSIO_THEME);

    return (
      <SettingsFormWrapper>
        {Object.keys(formFieldsConfig).length > 0 ? (
          <CollapseBox header={<Msg id="WEBEDI_SETTINGS_TITLE" />}>
            <Helmet>
              <title>{intl.formatMessage({id: 'WEBEDI_SETTINGS_TITLE'})}</title>
            </Helmet>
            <FinalForm
              initialValues={formValues}
              onSubmit={this.onSubmit}
              disabled={!formEnabled}
              themeSpecificInfo={{
                theme: userConfig?.theme || ECOSIO_THEME,
                primaryColor: currentTheme?.primaryColor,
                secondaryColor: currentTheme?.secondaryColor,
              }}
              decorators={[webEdiSettingsFormDecorator]}
              fields={formFieldsConfig}>
              {({handleSubmit, submitting, invalid, pristine, values}) => (
                <Form onSubmit={handleSubmit}>
                  {settingsConfiguration.indexOf(SETTING_TYPE_GS1) >= 0 ? (
                    <FormFieldsWrapper>
                      <Header as="h5">
                        {intl.formatMessage({id: 'WEBEDI_SETTINGS_GS1_HEADER'})}
                      </Header>
                      <p>
                        {intl.formatMessage({
                          id: 'WEBEDI_SETTINGS_GS1_HEADER_DESCRIPTION',
                        })}
                      </p>
                      <Grid>
                        <Grid.Row columns={1}>
                          <Grid.Column>
                            <Input
                              data-spec="webedi-settings-gs1"
                              path="GS_1"
                              name="GS_1"
                              validate={mergeValidators(
                                required,
                                gs1Length,
                                mustBeNumber,
                                ssccLength
                              )}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Input
                              data-spec="webedi-settings-sscc-offset"
                              path="SSCC_COUNTER_OFFSET"
                              name="SSCC_COUNTER_OFFSET"
                              validate={mergeValidators(
                                mustBeNumber,
                                ssccLength
                              )}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Input
                              data-spec="webedi-settings-sscc-counter-next"
                              path="NEXT_SSCC_VALUE"
                              name="NEXT_SSCC_VALUE"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </FormFieldsWrapper>
                  ) : null}

                  {renderDraftFields && (
                    <WebediSettingsDraftAndDelayFields
                      formContextValues={values}
                    />
                  )}

                  <SubmitButton
                    dataSpec="account-page-submitButton"
                    disabled={invalid || submitting || pristine}
                    disableButton={submitting}
                    buttonName="SAVE_CHANGES"
                    onClick={(e) => handleSubmit(e)}
                  />
                </Form>
              )}
            </FinalForm>
          </CollapseBox>
        ) : (
          <h1>{intl.formatMessage({id: 'NO_COMPANY_WEBEDI_SETTINGS'})}</h1>
        )}
      </SettingsFormWrapper>
    );
  }
}
export default injectIntl(connect(mapStateToProps)(WebEdiSettingsForm));
