import {set} from 'lodash';
import {round} from './math';

/**
 * Creates an empty order document with a calculated footer containing
 * vat rates, subtotal and total based on the ordersLineItems.
 */
export const calculateFooterTaxes = (ordersLineItems) => {
  const taxes = [];
  let subTotal = 0;
  const newOrderDocumentWithCalculatedFooter = {};

  ordersLineItems
    .map((lineItem) => {
      if (lineItem.lineItemActionRequest !== 'REJECTED') {
        subTotal = round(subTotal + lineItem.lineItemAmount);
        return {
          vatrate: lineItem.vatrate || 0,
          taxableAmount: lineItem.lineItemAmount,
          vatamount: 0,
        };
      }
    })
    .forEach((item) => {
      // ignore items without a taxRate
      if (!item || !item.vatrate) {
        return;
      }

      const foundIndex = taxes.findIndex(
        (taxitem) => taxitem.vatrate === item.vatrate
      );

      if (foundIndex > -1) {
        const txa = taxes[foundIndex].taxableAmount + item.taxableAmount;
        taxes[foundIndex] = {
          vatrate: taxes[foundIndex].vatrate,
          taxableAmount: round(txa),
          vatamount: round((txa / 100) * taxes[foundIndex].vatrate),
        };
      } else {
        taxes.push({
          vatrate: item.vatrate,
          taxableAmount: round(item.taxableAmount),
          vatamount: round((item.taxableAmount / 100) * item.vatrate),
        });
      }
    });

  set(
    newOrderDocumentWithCalculatedFooter,
    'footer.invoiceFooter.invoiceTotals.totalTaxableAmount',
    subTotal
  );

  set(
    newOrderDocumentWithCalculatedFooter,
    'footer.invoiceFooter.invoiceTotals.invoiceAmount',
    taxes.reduce((acc, cur) => acc + cur.vatamount, subTotal)
  );

  set(
    newOrderDocumentWithCalculatedFooter,
    'footer.invoiceFooter.vatrates',
    taxes
  );

  return newOrderDocumentWithCalculatedFooter;
};
