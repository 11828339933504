import {legacy_createStore as createStore, applyMiddleware} from 'redux';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import {thunk} from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import createRootReducer from './reducers';
import {BASE_URL} from './constants';

if (typeof BASE_URL === 'undefined') {
  console.warn('BASE_URL is undefined!');
}

export const history = createBrowserHistory({basename: BASE_URL});
const routingMiddleware = routerMiddleware(history);

const middleWares = [
  applyMiddleware(routingMiddleware),
  applyMiddleware(thunk),
];

const initState = {};

const enhancer = composeWithDevTools(...middleWares);

export default createStore(createRootReducer(history), initState, enhancer);
