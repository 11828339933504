import {get} from 'lodash';
import {LOCATION_CHANGE} from 'connected-react-router';
import {isInboundDocType, isOutboundDocType} from '../util/scenarioUtil';
import {
  postEnvelopeCustomProperties,
  getEnvelope,
  postEnvelopeStatus,
} from './fetcher';

export const TYPES = {
  SAVEPROPS_REQUEST: 'envelope/props/request',
  SAVEPROPS_SUCCESS: 'envelope/props/success',
  SAVEPROPS_ERROR: 'envelope/props/error',

  STATUSCHANGE_SUCCESS: 'envelope/status/success',
  STATUSCHANGE_ERROR: 'envelope/status/error',
  STATUSCHANGE_REQUEST: 'envelope/status/request',

  REQUEST: 'envelope/request',
  SUCCESS: 'envelope/success',
  ERROR: 'envelope/error',
};

const initState = {
  data: [],
  fetching: true,
  props: {
    error: null,
    posting: false,
  },
  status: {
    error: null,
    posting: false,
  },
  error: null,
};

const SORT = {
  _p: 'createdAt',
  DESC: (a, b) =>
    a[SORT._p] > b[SORT._p] ? -1 : a[SORT._p] < b[SORT._p] ? 1 : 0,
  ASC: (a, b) =>
    a[SORT._p] < b[SORT._p] ? -1 : a[SORT._p] > b[SORT._p] ? 1 : 0,
};

export const sortDocuments = (documents, sortOrder) => {
  const sorter = SORT[sortOrder];

  if (!Array.isArray(documents)) {
    console.warn('No documents array provided to sort, got ', typeof documents);
    return;
  }

  if (typeof sorter !== 'function') {
    return;
  }

  documents.sort(sorter);
};

export const prepareDocuments = (envelope, scenario) => {
  const MERGED_DOC_ID = 'MERGED_ERPEL_INDUSTRY_MESSAGE';
  const MERGED = {
    documentTypeId: MERGED_DOC_ID,
    // This is fed into <FormatDateTime/> from @ecosio/components
    // which expectes a date string (iso)
    createdAt: new Date().toISOString(),
    uuid: 'merged',
  };

  const sortOrder = get(scenario, 'documentDropdown.sortOrder');

  if (typeof sortOrder === 'string') {
    sortDocuments(envelope.documents, sortOrder);
  } else {
    if (scenario && scenario.uuid) {
      console.warn(
        `Sort order undefined in scenario: ${scenario?.uuid}`,
        scenario
      );
    } else {
      console.error(`Sort order and scenario is undefined`);
    }
  }

  if (scenario.documentMerger && typeof scenario.documentMerger === 'object') {
    const toMerge = get(scenario, 'documentMerger.documentTypesToMerge', []);
    const mergedPosition = get(
      scenario,
      'documentDropdown.mergedDocumentPosition',
      'TOP'
    );

    envelope.selectableDocuments =
      mergedPosition === 'TOP'
        ? [MERGED, ...envelope.documents]
        : [...envelope.documents, MERGED];

    envelope.selectableDocuments = envelope.selectableDocuments.filter(
      (doc) =>
        toMerge.includes(doc.documentTypeId) ||
        doc.documentTypeId === MERGED_DOC_ID
    );

    envelope.metaDocuments = {};
    envelope.documents
      .filter(
        (doc) =>
          (!toMerge.includes(doc.documentTypeId) &&
            doc.documentTypeId !== MERGED_DOC_ID) ||
          /** https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/236#note_167120*/
          isOutboundDocType(doc.documentTypeId, scenario)
      )
      .forEach((item) => {
        envelope.metaDocuments[item.documentTypeId]
          ? envelope.metaDocuments[item.documentTypeId].push(item)
          : (envelope.metaDocuments[item.documentTypeId] = [item]);
      });
  } else {
    envelope.metaDocuments = {};

    envelope.selectableDocuments = envelope.documents.filter((doc) =>
      isInboundDocType(doc.documentTypeId, scenario)
    );

    envelope.documents
      .filter((doc) => isOutboundDocType(doc.documentTypeId, scenario))
      // eslint-disable-next-line sonarjs/no-identical-functions
      .forEach((item) => {
        envelope.metaDocuments[item.documentTypeId]
          ? envelope.metaDocuments[item.documentTypeId].push(item)
          : (envelope.metaDocuments[item.documentTypeId] = [item]);
      });
  }
};

const postEnvelopesPropsRequest = () => {
  return {
    type: TYPES.SAVEPROPS_REQUEST,
  };
};

const postEnvelopesPropsSuccess = (props) => {
  return {
    customProperties: props,
    type: TYPES.SAVEPROPS_SUCCESS,
  };
};

const postEnvelopesPropsError = (error) => {
  return {
    type: TYPES.SAVEPROPS_ERROR,
    error: error.message,
  };
};

export const isValidProp = ({path, value}) =>
  typeof path !== 'undefined' && typeof value !== 'undefined';

// TODO: What status comes back if something weird occurs ?
export const postEnvProperties = (envelopeUuid, props) => (dispatch) => {
  dispatch(postEnvelopesPropsRequest());
  // we don't want undefined in the database
  const filteredProps = props.filter(isValidProp);
  return postEnvelopeCustomProperties(envelopeUuid, filteredProps)
    .then(() => {
      return dispatch(postEnvelopesPropsSuccess(props));
    })
    .catch((error) => dispatch(postEnvelopesPropsError(error)));
};

const postEnvelopesStatusRequest = () => {
  return {
    type: TYPES.STATUSCHANGE_REQUEST,
  };
};

const postEnvelopeStatusSuccess = (status) => {
  return {
    status,
    type: TYPES.STATUSCHANGE_SUCCESS,
  };
};

const postEnvelopeStatusError = (error) => ({
  type: TYPES.STATUSCHANGE_ERROR,
  error: error.message,
});

export const postEnvStatus = (envelopeUuid, scenario, status) => (dispatch) => {
  dispatch(postEnvelopesStatusRequest());
  return postEnvelopeStatus(envelopeUuid, status)
    .then(() => {
      return dispatch(postEnvelopeStatusSuccess(status));
    })
    .catch((error) => dispatch(postEnvelopeStatusError(error)));
};

const fetchEnvelopeRequest = () => ({
  type: TYPES.REQUEST,
});

export const fetchEnvelopeSuccess = (data) => {
  return {
    type: TYPES.SUCCESS,
    data: data,
  };
};

const fetchEnvelopeError = (error) => ({
  type: TYPES.ERROR,
  error: error.message,
});

export const fetchEnvelope = (envelopeUuid, scenario) => (dispatch) => {
  dispatch(fetchEnvelopeRequest());
  return getEnvelope(envelopeUuid)
    .then((res) => {
      prepareDocuments(res.data, scenario);
      return dispatch(fetchEnvelopeSuccess(res.data));
    })
    .catch((error) => dispatch(fetchEnvelopeError(error)));
};

export function reducer(state = initState, action) {
  switch (action.type) {
    case TYPES.SAVEPROPS_REQUEST:
      return {
        ...state,
        props: {
          error: null,
          posting: true,
        },
      };

    case TYPES.SAVEPROPS_SUCCESS:
      return {
        ...state,
        props: {
          error: null,
          posting: false,
        },
      };

    case TYPES.SAVEPROPS_ERROR:
      return {
        ...state,
        props: {
          error: action.error,
          posting: false,
        },
      };

    case TYPES.STATUSCHANGE_ERROR:
      return {
        ...state,
        status: {
          error: action.error,
          posting: false,
        },
      };

    case TYPES.STATUSCHANGE_REQUEST:
      return {
        ...state,
        status: {
          error: null,
          posting: true,
        },
      };

    case TYPES.STATUSCHANGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          status: action.status,
        },
        status: {
          error: null,
          posting: false,
        },
        fetching: false,
        error: null,
      };

    case TYPES.REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case TYPES.SUCCESS:
      return {
        ...state,
        data: action.data,
        fetching: false,
        error: null,
      };

    case TYPES.ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };

    // reset the state for each page reload
    case LOCATION_CHANGE:
      return action.payload.location.hash === '' ? initState : state;

    default:
      return state;
  }
}
