export const exportErrorMessageTranslationKeyFromHTTPResponse = (errorBody) => {
  return errorBody.response?.data?.errorTranslationKey;
};

const getDefaultDownload = (headerContent) => {
  console.warn(
    'Unable to extract filename from content-disposition header ',
    headerContent
  );
  return 'Download';
};

export const extractContentDispoFilename = (headerContent) => {
  // https://regex101.com/r/hJ7tS6/51
  const matches =
    /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/.exec(
      headerContent
    );

  if (!matches) {
    return getDefaultDownload(headerContent);
  }

  if (matches.length === 4) {
    // filename is not wrapped in quotes
    if (typeof matches[3] !== 'undefined') {
      return matches[3];
      // filename is wrapped in quotes
    } else if (typeof matches[2] !== 'undefined') {
      return matches[2];
    }
  } else {
    return getDefaultDownload(headerContent);
  }

  return getDefaultDownload(headerContent);
};
