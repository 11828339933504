import {filterLineItems} from '@ecosio/pathform';
import {LOCATION_CHANGE} from 'connected-react-router';
import {pageConfigForDocType} from '../util/scenarioUtil';
import {
  INDUSTRY_ORDCHG,
  INDUSTRY_ORDER,
  INDUSTRY_ORDER_RESPONSE,
} from '../shapes/supportedDocTypes';
import {preProcessOrder} from '../preprocessors/ordersPreprocessor';
import {CALCULATION_ENABLED_TYPES} from '../shapes/scenarios';

import groupDates from '../preprocessors/groupDates';
import webEdiEvent, {WEBEDI_DOCUMENT_PROCESSING_FAILED} from '../modules/Event';
import {getDocumentExchange} from './fetcher';
import {fetchEnvelope} from './envelope';

export const TYPES = {
  REQUEST: 'document/request',
  SUCCESS_GET: 'document/success',
  ERROR_GET: 'document/error',
};

const initState = {
  data: {},
  fetching: true,
  error: null,
  pageConfig: null,
  lineItemFields: [],
  deliveryDates: {
    data: [],
    error: null,
  },
};

const fetchDocumentRequest = () => ({
  type: TYPES.REQUEST,
});

const fetchDocumentSuccess = ({
  data,
  pageConfig,
  lineItemFields,
  deliveryDates,
}) => {
  return {
    type: TYPES.SUCCESS_GET,
    data: data,
    deliveryDates,
    pageConfig,
    lineItemFields,
  };
};

export const fetchDocumentError = (error, senarioUuid, envelopeUuid) => {
  const isBackEndError = error?.response?.status;

  //trigger events only for frontend errors here, backend error events are already triggered in backend controller
  if (!isBackEndError) {
    const errorEvent = {
      eventName: WEBEDI_DOCUMENT_PROCESSING_FAILED,
      scenarioUuid: senarioUuid,
      envelopeUuid: envelopeUuid,
      errorMessage: error?.message,
    };
    webEdiEvent.triggerEvent(errorEvent);
  }
  return {
    type: TYPES.ERROR_GET,
    error: error.message,
  };
};

export const createExchangePayload = (
  exchange,
  scenario,
  doGroupDates = false
) => {
  if (!exchange || !exchange.meta) {
    return;
  }

  const docType = exchange.meta.documentTypeId;
  const pageConfig = pageConfigForDocType(scenario, docType);

  if (!pageConfig) {
    const errorMessage = `Could not load a pageConfig for scenario ${scenario?.uuid}`;

    console.warn(errorMessage);
    return;
  }

  const allFields =
    pageConfig && pageConfig.formFields ? pageConfig.formFields : {};

  return {
    data: exchange,
    pageConfig,
    // only groupDates when a desadv is configured - not needed otherwise
    deliveryDates: doGroupDates
      ? groupDates(exchange, scenario)
      : {data: [], error: null},
    lineItemFields: filterLineItems(allFields),
  };
};

/**
 * Takes the documentExchange response object and updated the data.document
 * property with the document.
 */
export const updateResponseDocument = (response, document) => {
  return Object.assign(
    {},
    {
      ...response,
      data: {
        ...response?.data,
        document,
      },
    }
  );
};

/**
 * Checks if the documentType requires recalculation of lineItems
 * for preprocessing after loading from the server.
 *
 * Not needed if lineItemConfiguration.amountCalculation is set to NONE
 */
export const shouldRecalculateLineItems = (scenario, documentType) => {
  const pageConfig = pageConfigForDocType(scenario, documentType);
  const calcType = pageConfig?.lineItemConfiguration?.amountCalculation;
  return CALCULATION_ENABLED_TYPES.indexOf(calcType) > -1;
};

// TODO: this could be combined in one backend call which returns
// both the envelope and the exchange
const getDocumentExchangeWithScenario = (
  envelopeUuid,
  documentUuid,
  scenario,
  dispatch
) => {
  return getDocumentExchange(envelopeUuid, documentUuid)
    .then((res) => {
      let responseDoc = res?.data?.document;
      const documentType = res?.data?.meta?.documentTypeId;

      switch (documentType) {
        case INDUSTRY_ORDCHG:
        case INDUSTRY_ORDER_RESPONSE:
        case INDUSTRY_ORDER:
          // only recalculate if amount calculation is configured
          const shouldCalc = shouldRecalculateLineItems(scenario, documentType);

          responseDoc = shouldCalc ? preProcessOrder(responseDoc) : responseDoc;
          break;
      }

      res = updateResponseDocument(res, responseDoc);
      const exchangePayload = createExchangePayload(res?.data, scenario);
      if (typeof exchangePayload === 'undefined') {
        const error = {
          message: 'Could not create a payload for the backend exchange',
        };
        return dispatch(
          fetchDocumentError(error, scenario?.uuid, envelopeUuid)
        );
      } else {
        return dispatch(fetchDocumentSuccess(exchangePayload));
      }
    })
    .catch((error) => {
      const errorMessage = error?.message;

      if (errorMessage) {
        console.error(`Fetching document failed: ${error?.message}`, error);
      } else if (error) {
        console.error(
          `Fetching document failed for scenario : ${scenario?.uuid}`,
          error
        );
      } else {
        console.error(
          `Fetching document failed and error object is undefined for scenario : 
          ${scenario?.uuid}
        `
        );
      }

      dispatch(fetchDocumentError(error, scenario?.uuid, envelopeUuid));
    });
};

/**
 * TODO
 * Currently fetches first the envelope and then the scenario. Should
 * be combined in a single backend request.
 */
export const fetchDocumentWithEnvelope =
  (envelopeUuid, documentUuid, scenario) => (dispatch) => {
    dispatch(fetchDocumentRequest());

    return dispatch(fetchEnvelope(envelopeUuid, scenario)).then(() => {
      return getDocumentExchangeWithScenario(
        envelopeUuid,
        documentUuid,
        scenario,
        dispatch
      );
    });
  };

/**
 * Fetches a single document.
 */
export const fetchDocument =
  (envelopeUuid, documentUuid, scenario) => (dispatch) => {
    dispatch(fetchDocumentRequest());
    return getDocumentExchangeWithScenario(
      envelopeUuid,
      documentUuid,
      scenario,
      dispatch
    );
  };

export function reducer(state = initState, action) {
  switch (action.type) {
    /*
      if its an ID Anchor just keep the State as it is
      Otherwise return the initState to reset the State (random State Bug)
    */
    case LOCATION_CHANGE:
      return action.payload.location.hash === '' ? initState : state;

    case TYPES.REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case TYPES.SUCCESS_GET:
      return {
        ...state,
        data: action.data,
        pageConfig: action.pageConfig,
        lineItemFields: action.lineItemField,
        deliveryDates: action.deliveryDates,
        fetching: false,
        error: null,
      };

    case TYPES.SUCCESS_POST:
      return {
        ...state,
        data: action.data,
        fetching: false,
        error: null,
      };

    case TYPES.ERROR_POST:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case TYPES.ERROR_GET:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
