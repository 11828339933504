/* eslint-disable react/no-array-index-key */
import React from 'react';
import {Dropdown, Button, Popup} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import htmlParse from 'html-react-parser';
import {isEmpty} from 'lodash';
import {FormatDate, intlShape, toast} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import jexl from 'jexl';
import {dynID} from '@ecosio/pathform';
import styled from 'styled-components';
import {startFileDownload, startPolling} from '../../../reducers/fileDownload';
import {scenarioShape} from '../../../shapes/scenarios';
import {STANDARD_TOAST_DURATION} from '../../../helpers/constants';

const StyledDownloadDateLabel = styled.div`
  color: ${ecosioColors.greyShades[1]} !important;
`;

const matchesDocumentType = (config, selectedTypes) => {
  return selectedTypes.some((selected) => {
    const type = config.documentTypeId || config.webediDocumentType;
    return selected === type;
  });
};

export const checkFileRenderingConditonExpression = (conf, documentData) => {
  if (conf?.enableFileRenderingExpression) {
    return jexl.evalSync(`${conf?.enableFileRenderingExpression}`, documentData)
      ? conf
      : null;
  } else {
    return conf;
  }
};

const getPdfConfigurations = (
  scenario,
  metaDocuments,
  ordersDocType,
  currentDocumentTypeID,
  documentData = {}
) => {
  let pdfConfigurations = null;

  if (
    scenario.downloadOptionOfAllPdfsOnMainDropDown &&
    !isEmpty(metaDocuments)
  ) {
    pdfConfigurations = (pageConfigs) =>
      pageConfigs
        .map((p) => p.downloadConfigurations)
        .reduce((acc, next) => acc.concat(next), [])
        .filter((conf) =>
          matchesDocumentType(conf, [
            ...ordersDocType,
            ...Object.keys(metaDocuments),
          ])
        )
        .filter((conf) =>
          checkFileRenderingConditonExpression(conf, documentData)
        );
  } else {
    pdfConfigurations = (pageConfigs) =>
      pageConfigs
        .map((p) => p.downloadConfigurations)
        .reduce((acc, next) => acc.concat(next), [])
        .filter((conf) => matchesDocumentType(conf, [currentDocumentTypeID]))
        .filter((conf) =>
          checkFileRenderingConditonExpression(conf, documentData)
        );
  }

  return pdfConfigurations;
};

export const getRenderingConfigurations = (
  scenario,
  metaDocuments,
  currentDocumentTypeID,
  documentData = {}
) => {
  //we should pass OrdersDoctype here, because metaDocuments has no ordersDocType
  const ordersDocType = ['XML_ORDERS_1p0_ERPEL_INDUSTRY_MESSAGE'];

  const pdfConfigurations = getPdfConfigurations(
    scenario,
    metaDocuments,
    ordersDocType,
    currentDocumentTypeID,
    documentData
  );
  return pdfConfigurations(scenario.inboundWebDocTypes).concat(
    pdfConfigurations(scenario.outboundWebDocTypes)
  );
};

class DownloadPdfButton extends React.Component {
  static propTypes = {
    envelopeUuid: PropTypes.string.isRequired,
    scenario: scenarioShape.isRequired,
    // These props are injected via redux
    startFileDownload: PropTypes.func.isRequired,
    fileDownload: PropTypes.any.isRequired,
    startPolling: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    currentDocumentTypeID: PropTypes.string.isRequired,
    currentDocumentUuid: PropTypes.string.isRequired,
    metaDocuments: PropTypes.object,
    dataSpec: PropTypes.string,
    intl: intlShape,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const {scenario, metaDocuments, currentDocumentTypeID, documentData} =
      props;
    this.state = {
      pdfConfigurations: getRenderingConfigurations(
        scenario,
        metaDocuments,
        currentDocumentTypeID,
        documentData
      ),
      loading: false,
    };
  }

  onItemClicked = (config, documentUuid) => {
    const {envelopeUuid, locale, scenario, fileDownload, intl} = this.props;

    const downloadId = fileDownload?.downloadId;
    const downloadPending = fileDownload?.polling;

    if (downloadId && downloadPending) {
      //When there is already a downloadOperation running, trigger a toast.
      toast({
        title: intl.formatMessage({
          id: 'ONE_RENDERING_ALREADY_IN_PROGRESS_TITLE',
        }),
        description: intl.formatMessage({
          id: 'ONE_RENDERING_ALREADY_IN_PROGRESS_TITLE_DESCRIPTION',
        }),
        type: 'warning',
        time: STANDARD_TOAST_DURATION,
      });

      //When there is already a downloadOperation running,return here and send no further download requests.
      return;
    }

    this.setState({loading: true});

    this.props
      .startFileDownload(
        envelopeUuid,
        documentUuid,
        scenario.uuid,
        locale,
        config?.pdfRenderingConfigID,
        config?.fileFormat
      )
      .then(({downloadId}) => this.props.startPolling(downloadId))
      .catch((err) => console.error(err));
  };

  dropDownItem = (config, idx, documentUuid) => {
    const {intl} = this.props;
    const toolTipContentTranslationKey = `${config?.outputDocumentTypeId}_TOOLTIP`;

    return (
      <Dropdown.Item
        onClick={() => this.onItemClicked(config, documentUuid)}
        key={idx}>
        <Popup
          content={
            intl.messages[toolTipContentTranslationKey]
              ? intl.formatMessage({
                  id: dynID(`${config.outputDocumentTypeId}_TOOLTIP`),
                })
              : intl.formatMessage({
                  id: dynID(`${config.outputDocumentTypeId}`),
                })
          }
          trigger={
            <div>
              <FormattedMessage id={dynID(config?.outputDocumentTypeId)}>
                {(chunks) => {
                  return htmlParse(chunks.join(''));
                }}
              </FormattedMessage>
            </div>
          }
        />
      </Dropdown.Item>
    );
  };
  render() {
    const {
      fileDownload,
      metaDocuments,
      scenario,
      currentDocumentUuid,
      dataSpec,
      intl,
      disabled,
    } = this.props;
    const {pdfConfigurations, loading} = this.state;

    const polling = fileDownload.polling && loading;

    if (!polling && loading) {
      this.setState({loading: false});
    }
    return pdfConfigurations.length === 0 ? null : (
      <Dropdown
        data-spec={dataSpec}
        direction="left"
        labeled
        trigger={
          <Button
            content={intl.formatMessage({id: 'GENERAL_DOWNLOAD'})}
            icon="download"
            style={{
              background: disabled
                ? ecosioColors.greyShades[3]
                : ecosioColors.successGreen,
              marginRight: '2px',
            }}
          />
        }
        loading={polling}
        icon={null}
        disabled={polling || disabled}>
        <Dropdown.Menu data-spec="download-button-dropdown-menu">
          {pdfConfigurations
            .filter((config) => {
              const filteredMetaDocuments = Object.keys(metaDocuments).find(
                (key) => key === config.documentTypeId
              );
              return !filteredMetaDocuments;
            })
            .map((config, idx) => {
              const dropDownItems = [];

              dropDownItems.push(
                this.dropDownItem(config, idx, currentDocumentUuid)
              );

              return dropDownItems;
            })}
          {pdfConfigurations
            .filter((config) => {
              return Object.keys(metaDocuments).find(
                (key) => key === config.documentTypeId
              );
            })
            .map((config, idx) => {
              const dropDownItems = [];
              if (!scenario.downloadOptionOfAllPdfsOnMainDropDown) {
                dropDownItems.push(
                  this.dropDownItem(config, idx, currentDocumentUuid)
                );
              } else {
                Object.keys(metaDocuments)
                  .filter((meta) => config.documentTypeId === meta)
                  .forEach((key) => {
                    metaDocuments[key].forEach((document) =>
                      dropDownItems.push(
                        <Dropdown.Item
                          onClick={() =>
                            this.onItemClicked(config, document.uuid)
                          }
                          key={idx}>
                          <Popup
                            content={
                              intl.messages[
                                `${config?.outputDocumentTypeId}_TOOLTIP`
                              ]
                                ? intl.formatMessage({
                                    id: dynID(
                                      `${config.outputDocumentTypeId}_TOOLTIP`
                                    ),
                                  })
                                : intl.formatMessage({
                                    id: dynID(`${config.outputDocumentTypeId}`),
                                  })
                            }
                            trigger={
                              <div>
                                <div>
                                  <FormattedMessage
                                    id={dynID(config?.outputDocumentTypeId)}>
                                    {(chunks) => {
                                      return htmlParse(chunks.join(''));
                                    }}
                                  </FormattedMessage>
                                </div>
                                {document.createdAt && (
                                  <div>
                                    <StyledDownloadDateLabel>
                                      <FormatDate
                                        dateString={document.createdAt}
                                      />
                                    </StyledDownloadDateLabel>
                                  </div>
                                )}
                              </div>
                            }
                          />

                          {
                            //https://gitlab.ecosio.com/code/frontend/-/issues/1583
                            //TODO: OrdersPDF has no timestamp, removed this for now
                            /*  &nbsp;&nbsp;
                            <FormatDateTime
                              stripTimezone={false}
                              dateString={document.createdAt}
                            />*/
                          }
                        </Dropdown.Item>
                      )
                    );
                  });
              }

              return dropDownItems;
            })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

DownloadPdfButton.defaultProps = {
  metaDocuments: {},
  disabled: false,
};

const mapStateToProps = ({fileDownload, locales}) => ({
  fileDownload,
  locale: locales.locale,
});

export default injectIntl(
  connect(mapStateToProps, {startFileDownload, startPolling})(DownloadPdfButton)
);
