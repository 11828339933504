import './polyfills';
import {createRoot} from 'react-dom/client';
import App from './App.jsx';

const container =
  document.getElementById('root') || document.createElement('div');

const root = createRoot(container);

export default root.render(<App />);
