import {saveDocument} from '../helpers/utils';
import {
  getDownloadStatus as statusRequest,
  startFileDownload as startRequest,
  startBatchPdfDownload as startBatchRequest,
  downloadFile,
} from './fetcher';
import {
  setEnvelopesListState,
  setEnvelopesListstatusChangeDataReset,
} from './envelopesList';

export const TYPES = {
  START_REQUEST: 'fileDownloadStart/request',
  START_SUCCESS: 'fileDownloadStart/success',
  START_ERROR: 'fileDownloadStart/error',
  START_DOWNLOADING_REQUEST: 'fileStreamDownloading/request',
  START_DOWNLOADING_SUCCESS: 'fileStreamDownloading/success',
  START_DOWNLOADING_ERROR: 'fileStreamDownloading/error',

  STATUS_REQUEST: 'fileDownloadStatus/request',
  STATUS_SUCCESS: 'fileDownloadStatus/success',
  STATUS_ERROR: 'fileDownloadStatus/error',
};

//total time:  12,8333 minutes
const EXPONENTIAL_BACK_OFF_POLL_INTERVALS = [
  1, 1, 2, 2, 4, 5, 5, 5, 5, 10, 10, 10, 10, 10, 10, 20, 20, 20, 20, 20, 20, 40,
  40, 40, 40, 40, 40, 80, 80, 80, 80,
];

const MAX_POLL_COUNT = EXPONENTIAL_BACK_OFF_POLL_INTERVALS.length;

const startDownloadRequest = () => ({
  type: TYPES.START_REQUEST,
});

const startDownloadSuccess = (downloadId) => ({
  type: TYPES.START_SUCCESS,
  downloadId,
});

const startDownloadError = (error) => ({
  type: TYPES.START_ERROR,
  error: error.message,
});

const startFileStreamDownloadRequest = () => ({
  type: TYPES.START_DOWNLOADING_REQUEST,
});

const startFileStreamDownloadSuccess = () => ({
  type: TYPES.START_DOWNLOADING_SUCCESS,
});

const startFileStreamDownloadError = (error) => ({
  type: TYPES.START_DOWNLOADING_ERROR,
  error: error,
});

export const startPolling = (downloadId) => (dispatch) => {
  getStatus(dispatch, downloadId, 0);
};

const getStatusRequest = (downloadId) => ({
  type: TYPES.STATUS_REQUEST,
  downloadId,
});

const getStatusSuccess = (data) => ({
  type: TYPES.STATUS_SUCCESS,
  data,
});

const getStatusError = (error) => ({
  type: TYPES.STATUS_ERROR,
  error: error.message,
  errorReport: error?.errorReport,
});

const getStatus = (dispatch, downloadId, pollIntervalIndex) => {
  dispatch(getStatusRequest(downloadId));
  return statusRequest(downloadId)
    .then((res) => {
      const {downloadUrl, error} = res.data;
      if (error) {
        dispatch(
          getStatusError({message: error, errorReport: res?.data?.errorReport})
        );
      } else if (pollIntervalIndex >= MAX_POLL_COUNT - 1) {
        dispatch(
          getStatusError({message: 'Timeout getting PDF', errorReport: null})
        );
      } else if (downloadUrl) {
        dispatch(getStatusSuccess(res.data));
        //sends get request for file stream
        dispatch(startDownloadingFileStream(downloadUrl));
      } else {
        window.setTimeout(
          () => getStatus(dispatch, downloadId, pollIntervalIndex + 1),
          EXPONENTIAL_BACK_OFF_POLL_INTERVALS[pollIntervalIndex] * 1000
        );
      }
    })
    .catch((err) => dispatch(getStatusError(err)));
};

export const startDownloadingFileStream =
  (downloadUrl) => (dispatch, getState) => {
    dispatch(startFileStreamDownloadRequest());

    const {envelopesList} = getState();
    const envelopesUuidList = envelopesList?.envelopeUuids || [];
    const envelopesStatus = envelopesList?.status || null;

    return downloadFile(downloadUrl)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(startFileStreamDownloadSuccess());
          /*
        Since this is a blob we have to deal with the Filename ourselfs
        https://github.com/eligrey/FileSaver.js/issues/439
        */
          saveDocument(res);
          if (envelopesUuidList.length !== 0 && envelopesStatus) {
            dispatch(setEnvelopesListState());
          }
        }

        return res;
      })
      .catch((err) => {
        console.error(
          'File downloading failed with a valid url, error:  ',
          err
        );
        dispatch(setEnvelopesListstatusChangeDataReset());
        dispatch(startFileStreamDownloadError(err));
      });
  };

export const startFileDownload =
  (
    envelopeUuid,
    documentUuid,
    scenarioUuid,
    locale,
    pdfRenderingConfigID,
    fileFormat = null,
    pageConfigUuid = null,
    envelopeBasedFile = true,
    pdfRenderingConfigKey = null,
    reportMessageUuid = null
  ) =>
  (dispatch) => {
    dispatch(startDownloadRequest());
    return startRequest(
      envelopeUuid,
      documentUuid,
      scenarioUuid,
      locale,
      pdfRenderingConfigID,
      fileFormat,
      pageConfigUuid,
      envelopeBasedFile,
      pdfRenderingConfigKey,
      reportMessageUuid
    )
      .then((res) => dispatch(startDownloadSuccess(res.data)))
      .catch((err) => dispatch(startDownloadError(err)));
  };

export const startBatchPdfDownload =
  (scenarioUuid, action, params) => (dispatch) => {
    dispatch(startDownloadRequest());

    return startBatchRequest(scenarioUuid, action, params)
      .then((res) => dispatch(startDownloadSuccess(res.data)))
      .catch((err) => dispatch(startDownloadError(err)));
  };

export const initialState = {
  //translationKeys for errors for renderings
  errorReport: null,
  error: null,
  downloadId: null,
  downloadUrl: null,
  polling: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.START_REQUEST:
      return {
        ...state,
        error: null,
        errorReport: null,
        downloadId: null,
        polling: true,
      };
    case TYPES.START_SUCCESS:
      return {
        ...state,
        error: null,
        errorReport: null,
        downloadId: action.downloadId,
        polling: true,
      };
    case TYPES.START_ERROR:
      return {
        ...state,
        error: action?.error,
        errorReport: action?.errorReport,
        polling: false,
        downloadId: null,
      };

    case TYPES.START_DOWNLOADING_REQUEST:
      return {
        ...state,
        downloadId: null,
        polling: true,
        error: null,
        errorReport: null,
      };
    case TYPES.START_DOWNLOADING_SUCCESS:
      return {
        ...state,
        downloadUrl: null,
        downloadId: null,
        polling: false,
        error: null,
        errorReport: null,
      };

    case TYPES.START_DOWNLOADING_ERROR:
      return {
        ...state,
        downloadUrl: null,
        downloadId: null,
        polling: false,
        error: action?.error,
        errorReport: action?.errorReport,
      };

    case TYPES.STATUS_REQUEST:
      return {
        ...state,
        downloadId: action.downloadId,
        polling: true,
        error: null,
        errorReport: null,
      };
    case TYPES.STATUS_ERROR: {
      return {
        ...state,
        downloadId: null,
        error: action?.error,
        errorReport: action?.errorReport,
        polling: false,
      };
    }
    case TYPES.STATUS_SUCCESS:
      const {downloadUrl} = action.data;
      if (downloadUrl) {
        return {
          ...state,
          downloadUrl,
          downloadId: null,
          polling: false,
          error: null,
          errorReport: null,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
