export const CONTEXT_PATH = '/webedi';

/**
 * Allows to exclude some feature from a module configuration for a single supplier.
 */
export const WEB_EXCLUSION = {
  SCENARIO: 'SCENARIO',
  MODULE: 'MODULE',
  OUTBOUND_DOC_TYPE: 'OUTBOUND_DOC_TYPE',
};

export const CompanyTypes = {
  STANDARD: 'STANDARD',
  DEPARTMENT: 'DEPARTMENT',
  EXTERNAL: 'EXTERNAL',
  TEST: 'TEST',
  OWN: 'OWN',
  PARTNER: 'PARTNER',
};

export const MERGED_UUID = 'merged';

/**
 * AllowanceOrCharge field values for:
 * allowanceChargeQualifier
 *
 * @type {string}
 */
export const ALLOWANCE = 'A';
export const CHARGE = 'C';

/**
 * AllowanceOrCharge field values for:
 * allowanceChargeTypeCoded
 */
export const PERCENTAGE = 'P';
export const ABSOLUTE = 'A';

export const BASE_URL = '/webedi';

export const SETTING_TYPE_GS1 = 'GS_1';
export const SETTING_TYPE_SSCC = 'SSCC_COUNTER_OFFSET';
export const DRAFTS_DELAY_ENABLED = 'DRAFTS_DELAY_ENABLED';
export const DRAFTS_DELAY_TIME = 'DRAFTS_DELAY_TIME';
export const DRAFTS_DELAY_SCHEDULED = 'DRAFTS_DELAY_SCHEDULED';
export const DRAFTS_DELAY_TIME_IN_MS_FALLBACK = 900000; //15min

export const SSCC_PACKAGE_NUMBER_MAX_LENGTH = 17;

export const PACKAGING_NUMBER_INPUT_UUID = 'UUID_GENERATE';
export const PACKAGING_NUMBER_INPUT_SSCC = 'SSCC_GENERATE';
export const PACKAGING_NUMBER_INPUT_MANUAL = 'MANUAL';

export const CURRENCY_EUR = 'EUR';

export const DEFAULT_DISPATCH_MEASURE_UNIT = 'PCE';

export const DESADV_WITH_COMPLEX_PACKAGING_UNIQUE_POSITION_NUMBER_SEPERATOR =
  '_';

export const COMPLEX_ENRICHMENT_DEFAULT_INTERNAL_VAT_VARIANCE_CODE =
  'VAT_VARIANT_DEFAULT';

export const ORIGINAL_VAT_RATES_VARIANCE_CODE = 'VAT_VARIANT_DEFAULT';

export const INVOICE_LIN_ORDERS_VAT_RATE_IN_DETAILS_PROPERTIES_KEY =
  'ORDERS_VAT_RATE';

export const EnableRelativeCalculationForAllowanceConstants = {
  DEFAULT: 'DEFAULT',
  FALSE: 'FALSE',
  USER_SELECTION: 'USER_SELECTION',
};

export const MOMENT_ONLY_DATE_FORMAT = 'L';

export const MOMENT_DATE_AND_TIME_FORMAT = 'L LT';

export const DESADV_REQUIRED = 'DESADV_REQUIRED';
