/**
 * Round the value to arbitrary decimals.
 *
 * @param value
 * @param decimals
 * @return {number}
 */
export const round = (value, decimals = 2) => {
  const roundedValue = Math.round(`${value}e${decimals}`);
  return Number(`${roundedValue}e-${decimals}`);
};

/**
 * Returns the number param is its a number or 0 if not.
 * @param number
 * @return {*}
 */
export const ensureZero = (number) => {
  return typeof number === 'undefined' || isNaN(number) || Array.isArray(number)
    ? 0
    : Number(number);
};

/**
 * Calculate and round the percentage of a base value.
 * @param percentage
 * @param base
 * @return {number}
 */
export const percentageOf = (percentage, base) => {
  return round((base / 100) * round(percentage));
};

/**
 * Calculate the percentage of a base value without rounding
 * @param percentage
 * @param base
 * @return {number}
 */
export const percentageOfWithoutRound = (percentage, base) => {
  return (base / 100) * percentage;
};
