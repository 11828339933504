import {ALLOWANCE, PERCENTAGE} from '../constants';
import {round} from './math';

const sumScheduleLines = (scheduleLines) => {
  return scheduleLines
    .map((line) => round(parseFloat(line.scheduledQuantity)))
    .reduce((total, amount) => total + amount);
};

/**
 * TODO: Vat ?
 */
const recalculateAllowanceOrCharge = (baseAmount, allowanceOrCharge) => {
  // the percentage of allowance or charge
  let percentageOfAllowanceOrCharge = parseFloat(
    allowanceOrCharge.percentageOfAllowanceOrCharge
  );
  const percentageMissing =
    typeof percentageOfAllowanceOrCharge === 'undefined' ||
    percentageOfAllowanceOrCharge === null ||
    isNaN(percentageOfAllowanceOrCharge);
  // the base amount for percentage calculations
  const chargeBaseQuantity = baseAmount;

  // the absolute amount of allowance or charge
  let allowanceChargeAmount = parseFloat(
    allowanceOrCharge.allowanceChargeAmount
  );

  const allowanceChargeTypeCoded = allowanceOrCharge.allowanceChargeTypeCoded;

  // this is a percentage base AoC
  if (allowanceChargeTypeCoded === PERCENTAGE) {
    if (percentageMissing === true) {
      percentageOfAllowanceOrCharge = 0;
    }
    const calculatedAmount =
      (chargeBaseQuantity / 100) * parseFloat(percentageOfAllowanceOrCharge);
    allowanceChargeAmount = round(calculatedAmount);
  } else {
    // to make sure there is no ambiguity what type of allowance/charge
    // this is we remove any percentage present on the item
    percentageOfAllowanceOrCharge = undefined;
    if (
      typeof allowanceChargeAmount === 'undefined' ||
      allowanceChargeAmount === null ||
      isNaN(allowanceChargeAmount)
    ) {
      allowanceChargeAmount = 0;
    }
  }

  percentageOfAllowanceOrCharge =
    percentageMissing === false ? percentageOfAllowanceOrCharge : undefined;

  return {
    ...allowanceOrCharge,
    chargeBaseQuantity,
    percentageOfAllowanceOrCharge,
    allowanceChargeAmount,
  };
};

const calculateAllowanceOrChargeAmount = ({
  allowanceChargeQualifier,
  chargeBaseQuantity,
  allowanceChargeAmount,
}) => {
  return allowanceChargeQualifier === ALLOWANCE
    ? chargeBaseQuantity - allowanceChargeAmount
    : chargeBaseQuantity + allowanceChargeAmount;
};

export const recursiveAllowanceCalculation = (baseAmount, index, charges) => {
  const currentItem = charges[index];

  if (!currentItem) {
    console.warn(
      `Problem calculating currentItem - item is undefined ${index}`
    );
    return;
  }

  const recalculatedItem = recalculateAllowanceOrCharge(
    baseAmount,
    currentItem
  );
  charges[index] = recalculatedItem;
  const nextIndex = index + 1;
  const newBaseAmount = calculateAllowanceOrChargeAmount(recalculatedItem);

  if (nextIndex < charges.length) {
    // calculate the next item with a baseAmount set to the
    // allowanceChargeAmount of the previous item
    recursiveAllowanceCalculation(newBaseAmount, nextIndex, charges);
  }
};

export const recalculateSumOnly = (lineItem) => {
  const orderedQuantity = sumScheduleLines(lineItem.ordersScheduleLines);
  return {
    ...lineItem,
    orderedQuantity,
  };
};

/**
 * Recalculates the following values inside this lineItem:
 *
 * - lineItemAmount
 * - allowancesAndCharges.allowanceChargeAmount
 * - allowancesAndCharges.chargeBaseQuantity
 */
export const recalculateLineItem = (lineItem) => {
  if (!lineItem) {
    console.warn('No line item passed, cannot recalculate...');
    return lineItem;
  }

  const grossPrice = lineItem.currentItemPriceCalculationGross?.value;

  if (
    !grossPrice &&
    grossPrice !== 0 // an actual 0 should be allowed
  ) {
    // this seems to be a legit use case, as long as the line item
    // has required fields the turnaround document cannot be created
    // without entering a value.
    return lineItem;
  }

  // 0) We assume netprice is grossprice - since we have no allowances and charges
  let netPrice = grossPrice;

  // 1) calculate the orderedQuantity based on the total of all orderScheduleLines
  const orderedQuantity = lineItem.ordersScheduleLines.length
    ? sumScheduleLines(lineItem.ordersScheduleLines)
    : 0;

  // 2) calculate the line item total price
  const total = round(
    (parseFloat(grossPrice) / parseFloat(lineItem.unitPriceBasis)) *
      orderedQuantity
  );

  if (isNaN(total)) {
    console.warn(
      'Total was NaN after calculating lineItem: ',
      JSON.stringify(lineItem),
      grossPrice,
      orderedQuantity
    );
    return lineItem;
  }

  // 3) recursively calculate allowances and charges
  const allowancesAndCharges = lineItem.allowancesAndCharges || [];

  // 4a) if there are no allowances and charges, the lineItemAmount is the total
  let lineItemAmount = total;
  if (allowancesAndCharges.length) {
    // 4b) calculate allowances and charges
    recursiveAllowanceCalculation(total, 0, allowancesAndCharges);

    // 4c) set the lineItemAmount based on the AoC-Amount of the last allowanceOrCharge
    // but apply this only if allowance or change percentage is set for last AoC-item
    // else it can cause undefined total amount value (NaN)
    const lastAllowanceOrCharge =
      allowancesAndCharges[allowancesAndCharges.length - 1];
    if (!isNaN(parseFloat(lastAllowanceOrCharge.allowanceChargeAmount))) {
      lineItemAmount = calculateAllowanceOrChargeAmount(lastAllowanceOrCharge);
    }

    // 4d) Since we have allowances and charges - we calculate the netprice
    netPrice = round(
      lineItemAmount / (orderedQuantity / lineItem.unitPriceBasis)
    );
  }

  /**
   * ☢ ☢ ☢ DANGER ZONE ☢ ☢ ☢
   *
   * Do not put this assignment in the object of the return value.
   * Final-Form-Decorators will not be able to map the nested value
   * onto the form.
   *
   * It seems that final-form-decorators MUST operates on existing references,
   * hence creating a new nested object reference breaks the form update.
   */

  if (!lineItem.currentItemPriceCalculationNet) {
    lineItem.currentItemPriceCalculationNet = {};
  }

  lineItem.currentItemPriceCalculationNet['value'] = netPrice;

  // 5) construct the calculated line item
  return {
    ...lineItem,
    lineItemAmount,
    allowancesAndCharges,
    orderedQuantity,
  };
};
