import jexl from 'jexl';
import {comparators} from '@ecosio/pathform';

const jexlWithTransformFuctions = (expression, context) => {
  if (!expression || !context) {
    return null;
  }

  // transform for checking deliveryLineItems
  jexl.addTransform(
    'checkAllDelisPartialDeliveryIsTrueAndDispatchQuantityIsZero',
    (contextDeliveryLineItems) => {
      const deliveryLineItems = contextDeliveryLineItems || [];
      const partialDeliveryTrueAndDispatchQuantityZeroArray =
        deliveryLineItems.filter(
          (del) =>
            del?.partialDelivery === true &&
            `${del?.dispatchQuantity?.value}` === '0'
        );

      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (
        partialDeliveryTrueAndDispatchQuantityZeroArray.length &&
        deliveryLineItems.length &&
        partialDeliveryTrueAndDispatchQuantityZeroArray.length ===
          deliveryLineItems.length
      ) {
        return true;
      }

      return false;
    }
  );

  /**
   * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/966
   * drafts => check if current date is < targetDate
   */
  jexl.addTransform('compareIfcurrentDateIsBefore', (targetDate) => {
    const dateComperator = comparators['date'];
    return dateComperator['afterToday'](targetDate);
  });

  return jexl.evalSync(`${expression}`, context);
};

export {jexlWithTransformFuctions};
